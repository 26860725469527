import { CircularProgress } from "@mui/material";

interface Props {
  isLoading: boolean;
}

export const MapLoadingSpinner = ({ isLoading }: Props) => !isLoading ? null : (
  <CircularProgress
    className="absolute left-1 top-1"
    variant="indeterminate"
    size={30}
    color="secondary"
  />
);
