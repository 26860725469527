import { Typography } from "@mui/material";
import { useEffect, useRef, useMemo } from "react";
import { useDefaultCenter } from "../../../hooks/defaultcenter";
import { GeoMap } from "../../GeoMap";
import { GeoSearchBar } from "../../GeoSearchBar";

export const FaultLocationStep = () => {
  const { defaultLatitude, defaultLongitude } = useDefaultCenter();

  const geocoder = useRef<google.maps.Geocoder>(new google.maps.Geocoder());
  useMemo(() => {
    const location: google.maps.LatLngLiteral = {
      lat: Number(defaultLatitude),
      lng: Number(defaultLongitude),
    };

    let cityName: string = "";

    geocoder.current.geocode({ location, componentRestrictions: {}, }).then(response => {
      cityName = response.results
        .filter((result) => result.types.includes("locality"))
        .map((result) => result.address_components)
        .filter((components) => components.filter((component) => component.types.includes("locality")))
        .shift()
        ?.map((component) => component.short_name)
        .shift() || "";
    }).catch(console.error)

    return cityName;
  }, [defaultLatitude, defaultLongitude]);
  const setDefaultLocation = async () => {

    
  };

  useEffect(() => {

    setDefaultLocation();
  }, [defaultLatitude, defaultLongitude]);

  return (
    <>
      <Typography
        
        variant="h2"
        maxWidth="80%"
        textAlign="center"
      >{`Bitte wählen Sie die betreffende Straßenleuchte aus`}</Typography>
      <GeoSearchBar layout="row" />
      <GeoMap
        defaultFetchType="defaultCenter"
        showMaxMarkerInfo
        style={geoMapStyle} 
        elevation={0}
      />
    </>
  );
};

const geoMapStyle = { flex: 1, minHeight: 400, width: "100%" };

export default FaultLocationStep;
