import React, { memo, useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { Marker } from "./Marker";
import { Button, Paper, styled } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { MapLoadingSpinner } from "./MapLoadingSpinner";
import { useDebounce } from "../../hooks/useDebounce";
import { When } from "react-if";
import { LightPointsErrorBox } from "./LightPointsErrorBox";
import { HintInfoBox } from "./HintInfoBox";
import { useGeoMap } from "../../context/GeoMapContext";
import { LightPoint } from "../../models/LightPoint";
import { LightPointUtils } from "../../utils/lightPointUtils/lightPointUtils";

interface GeoMapProps {
  elevation?: number;
  style?: React.CSSProperties;
  showMaxMarkerInfo?: boolean;
  showAddNewLightPointInfo?: boolean;
  onShowFaultReport?: (id: string) => void;
  defaultFetchType: "defaultCenter" | "customerScope";
}

/**
 * Renders a map, where light points of a specific municipality can be located.
 * This component currently interacts with the `reportCreationFormContext`.
 * Therefore, the TODO is to make it more generic so it could be used elsewhere
 * in the application without leading to invalid states or unexpected behaviors.
 */
export const GeoMap = memo(({ onShowFaultReport, ...props }: GeoMapProps) => {
  const {
    allLightPoints,
    mapRef,
    mapIsLoading,
    isOnMoveMapEffectsBlocked,
    center,
    fetchLightPoints,
    lightPointsError,
    fitMapBounds,
  } = useGeoMap();

  const debounce = useDebounce();

  const handleOnBoundsChange = useCallback(() => {
    const map = mapRef.current;
    if (!map) return;
    const query = LightPointUtils.buildLightpointsQueryByMap(map);
    debounce(fetchLightPoints)(query);
  }, [debounce, fetchLightPoints, mapRef]);

  const handleZoomLevelChange = useCallback(() => {
    if (isOnMoveMapEffectsBlocked.current) {
      isOnMoveMapEffectsBlocked.current = false;
      return;
    }
    handleOnBoundsChange();
  }, [handleOnBoundsChange, isOnMoveMapEffectsBlocked]);

  const onLoad = useCallback(
    async (map: google.maps.Map) => {
      mapRef.current = map;
      pushSatelliteButtonOnMap(map);
      isOnMoveMapEffectsBlocked.current = true;
      if (allLightPoints.length) {
        fitMapBounds({ lightPoints: allLightPoints });
        return;
      }
      const initialLightPoints = await fetchLightPoints();
      fitMapBounds({ lightPoints: initialLightPoints });
    },
    [fetchLightPoints, allLightPoints, isOnMoveMapEffectsBlocked, mapRef, fitMapBounds]
  );

  function onUnmount() {
    mapRef.current = undefined;
    isOnMoveMapEffectsBlocked.current = false;
  }

  return (
    <Paper elevation={props.elevation} className="flex relative flex-col flex-1" style={props.style}>
      <When condition={lightPointsError}>
        <LightPointsErrorBox onClose={fetchLightPoints} />
      </When>
      <GoogleMap
        center={center.current}
        onUnmount={onUnmount}
        zoom={10}
        onLoad={onLoad}
        onZoomChanged={handleZoomLevelChange}
        onDragEnd={handleOnBoundsChange}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        <LightPointsAsMarker lightPoints={allLightPoints} onShowFaultReport={onShowFaultReport} />
      </GoogleMap>
      <When condition={!!props.showMaxMarkerInfo}>
        <HintInfoBox label="Es werden maximal 500 Leuchtstellen auf der Karte angezeigt" />
      </When>
      <When condition={!!props.showAddNewLightPointInfo}>
        <HintInfoBox label="Leuchtstelle nicht gefunden? Einfach auf die Karte klicken und neuen Standort festlegen" />
      </When>
      <MapLoadingSpinner isLoading={mapIsLoading} />
    </Paper>
  );
});

interface LightPointMarkersProps {
  onShowFaultReport?: (id: string) => void;
  lightPoints: LightPoint[];
}

const LightPointsAsMarker = memo(({ onShowFaultReport, lightPoints }: LightPointMarkersProps) => {

  return !lightPoints?.length ? null : (
    <>
      {lightPoints.map((lightPoint) => (
        <Marker key={lightPoint.number} lightingPoint={lightPoint} onShowFaultReport={onShowFaultReport} />
      ))}
    </>
)});

export const SatelliteButton = ({ map }: { map: google.maps.Map }) => {
  const [onSatelliteView, setOnSatelliteView] = useState(false);

  function handleOnClick() {
    if (onSatelliteView) {
      map.setMapTypeId("roadmap");
    } else {
      map.setMapTypeId("satellite");
    }
    setOnSatelliteView(!onSatelliteView);
  }

  return (
    <WhiteButton variant="contained" onClick={handleOnClick}>
      {onSatelliteView ? "Straßenansicht" : "Satellitenansicht"}
    </WhiteButton>
  );
};

const WhiteButton = styled(Button)({
  color: "rgb(102, 102, 102)",
  backgroundColor: "white",
  fontWeight: 600,
  width: 180,
  marginBottom: "23px",
  "&:hover": {
    color: "black",
    backgroundColor: "white",
  },
});

function pushSatelliteButtonOnMap(map: google.maps.Map) {
  const controlButtonDiv = document.createElement("div");
  ReactDOM.render(<SatelliteButton map={map} />, controlButtonDiv);
  map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(controlButtonDiv);
}

export default GeoMap;
