import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SYMPTOM_LABEL, Symptom } from "../../../models/Symptom";
import UtcToLocale from "../../../utility-components/UtcToLocale";
import { GOOGLE_MAPS_API_KEY } from "../../../App";
import { useGeoMap } from "../../../context/GeoMapContext";

const hostUrl = window.location.protocol.concat("//").concat(window.location.host);

interface SummaryStepProps {
  symptoms: Symptom[];
  remarksCitizen?: string;
  recognitionDateTime: Date;
}
export const SummaryStep = (props: SummaryStepProps) => {
  const { selectedMarker } = useGeoMap();

  return !selectedMarker ? null : (
    <>
      <Box display="flex" alignItems="center" gap="20px" flexWrap="wrap" justifyContent="center" width="100%">
        <Box>
          <Box display="flex" flexDirection="column" gap="15px">
            <Box display="flex" gap="10px">
               <Typography variant="h6" style={{ minWidth: 120 }}> Mastnummer: </Typography>
            
              <Typography style={{ flex: 1 }}>{selectedMarker.number}</Typography>
            </Box>

            <Box display="flex" gap="10px">
            <Typography variant="h6" style={{ minWidth: 120 }}> Adresse: </Typography>
              <Box style={{ flex: 1, padding:'3px' }}>
                {!!selectedMarker.street && (
                  <Typography>{selectedMarker.street + (selectedMarker.houseNumber ? " " + selectedMarker.houseNumber : "")}</Typography>
                )}
                {!!selectedMarker.city && (
                  <Typography>{(selectedMarker.district ? selectedMarker.district + ", " : "") + selectedMarker.city}</Typography>
                )}
                {!!selectedMarker.zipCode && <Typography>{selectedMarker.zipCode}</Typography>}
              </Box>
            </Box>
            <Box display="flex" gap="10px">
            <Typography variant="h6" style={{ minWidth: 120 }}>Symptome:</Typography>
            
                {props.symptoms.map((symptom) => (
                  <Typography style={{padding:'3px'}} key={symptom}>{SYMPTOM_LABEL[symptom]}</Typography>
                ))}
         
            </Box>

            {props.remarksCitizen && (
              <Box display="flex" gap="10px">
                  <Typography variant="h6" style={{ minWidth: 120 }}>Anmerkungen:</Typography>
                <Typography style={{ flex: 1, padding:'3px' }}>{props.remarksCitizen}</Typography>
              </Box>
            )}

            <Box display="flex" gap="10px">
            <Typography variant="h6" style={{ minWidth: 120 }}>Erkennungszeit:</Typography>
              <Typography style={{ flex: 1, padding:'3px' }}>
                <UtcToLocale datetime={props.recognitionDateTime.toISOString()} />
              </Typography>
            </Box>
          </Box>
        </Box>
        <img
          src={renderStatic({
            marker: {
              latitude: selectedMarker?.latitude,
              longitude: selectedMarker?.longitude,
              icon: encodeURI(hostUrl + "/lightpoint-marker.png"),
            },
          })}
          alt="map-snapshot"
          style={{ borderRadius: "50%", objectFit: "cover", width: "250px" }}
        />
      </Box>
    </>
  );
};

export default SummaryStep;

const renderStatic = (params: any) => {
  
  const defaultStaticRenderParams = new URLSearchParams([
    ["size", "400x400"], // TODO test same width as img where its showing
    ["key", GOOGLE_MAPS_API_KEY],
    ["zoom", "18"],
  ]);

  const _params = new URLSearchParams(defaultStaticRenderParams);

  params.size && _params.set("size", params.size);
  params.zoom && _params.set("zoom", params.zoom);

  if (!!params.marker && !!params.marker.latitude && !!params.marker.longitude) {
    const markerLocation = [params.marker.latitude, params.marker.longitude].join(",");
    const markerProps = `${markerLocation}${params.marker.icon ? "|icon=" + params.marker.icon : ""}`;
    _params.set("markers", markerProps);
  }

  return `https://maps.googleapis.com/maps/api/staticmap?${_params}`;
};
