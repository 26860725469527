import { Utils } from "../utils";

export function getParamsURLEncoded<T>(o?: T, customerScope?: string) {
  let s = new URLSearchParams();
  let a = "";
  if (customerScope) {
    s.set("customerScope", customerScope);
  }
  if (o) {
    (Object.keys(o) as Array<keyof T>).forEach((k) => {
      const paramKey = k.toString();
      let p = o[k] as any;
      if (p instanceof Date) {
        return s.set(paramKey, p.toISOString());
      } else if (Array.isArray(p)) {
        a += `&${paramKey}=` + p.join(`&${paramKey}=`);
      } else {
        p = (p)?.toString();
        if (Utils.hasText(p)) {
          s.set(paramKey, p);
        }
      }
    });
  }
  const spRes = s.toString();
  if (!spRes.length && a.length) a = a.replace("&", "?");
  return s.toString() + a;
}
