import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router";
import { Autocomplete, GoogleSearchItemResult } from "../../parts/Autocomplete";
import { useGeoMap } from "../../context/GeoMapContext";

export const SearchBar = () => {
  const mounted = useRef(false);
  const navigate = useNavigate();
  const { fetchLightPoints } = useGeoMap();
  const autocompleteService = useRef<google.maps.places.AutocompleteService>(new google.maps.places.AutocompleteService());
  const geocoder = useRef<google.maps.Geocoder>(new google.maps.Geocoder());
  const [searchOptions, setSearchOptions] = useState<GoogleSearchItemResult[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [isGeocoding, toggleIsGeocoding] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnClick = useCallback(async () => {
    try {
      toggleIsGeocoding(true);
      const response = await geocoder.current.geocode({
        address: placeId ? null: searchInput,
        placeId:placeId 
      });
      const location = response.results[0]?.geometry.location;

      const queryParams = new URLSearchParams();
      if (!location) {
        setErrorMessage("Für diese Addresse konnten keine Geokoordinaten ermittelt werden.");
        return;
      }
      const lat = location.lat();
      const lng = location.lng();
      queryParams.set("lat", String(lat));
      queryParams.set("lng", String(lng));

      const data = await fetchLightPoints({
        ceLatitude: lat,
        ceLongitude: lng,
      });

      if (!data?.length) {
        setErrorMessage(
          "Für diesen Standort sind wir leider nicht zuständig. Bitte wenden Sie sich an Ihre Stadt oder Gemeinde. Vielen Dank."
        );
      } else {
        navigate(`/stoerung-melden?${queryParams}`);
      }
    } catch (err) {
      console.error(`Error geocoding ${searchInput}`, err);
    } finally {
      mounted.current && toggleIsGeocoding(false);
    }
  }, [toggleIsGeocoding, navigate, fetchLightPoints, searchInput, placeId]);

  const getDefaultLocation = async (location: google.maps.LatLngLiteral) => {
    try {
      const response = await geocoder.current?.geocode({
        location,
      });
      setSearchInput(response.results[0]?.formatted_address || "");
      setPlaceId(response.results[0]?.place_id || "");
    } catch (error) {
      console.error("Error geocoding the given location", error);
    }
  };

  const onSelectedItemChange = async (description: string, placeId?: string) => {
   setSearchInput(description);
   if(placeId) {
    setPlaceId(placeId);
   }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      getDefaultLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    setErrorMessage("");

    const request: google.maps.places.AutocompletionRequest = {
      input: "Baden-Württemberg, " + searchInput,
      componentRestrictions: { country: "de" },
      types: ["(cities)"],
    };

    autocompleteService.current.getPlacePredictions(request, (results) => {
      setSearchOptions(results?.map((prediction) => prediction) || []);
    });
  }, [searchInput]);

  return (
    <Box display="flex" flexDirection="column" gap="30px" alignItems="center">
      <Autocomplete
        id="home-location-autocomplete"
        placeholder="Ort"
        error={!!errorMessage}
        errorMessage={errorMessage}
        onChange={onSelectedItemChange}
        className="w-2/3"
        options={searchOptions}
        value={searchInput}
        popupIcon={null}
      />
      <Button
        color="primary"
        onClick={handleOnClick}
        style={{
          maxWidth: "300px",
        }}
        fullWidth
        variant="contained"
        disabled={!searchInput}
      >
        {isGeocoding ? (
          <CircularProgress variant="indeterminate" style={{ color: "white", width: "26px", height: "26px" }} />
        ) : (
          "Leuchte finden und online melden"
        )}
      </Button>
    </Box>
  );
};

export default SearchBar;
