import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../theme/theme";
import CircleIcon from '@mui/icons-material/Circle';

export const DangerHintBox = () => {
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography 
        variant="h3"
        fontWeight="bold"
        sx={{
          textDecoration: "underline",
          textDecorationColor: theme.palette.error.main,
          textDecorationThickness: 3,
          textUnderlineOffset: 10,
          textAlign: "center"
        }}
      >
        Wann liegt eine Gefahr vor?<br />
      </Typography>
      
      <List sx={{ listStyleType: 'disc' }}>
        <ListItem sx={{padding:'0px'}}>
          <ListItemIcon sx={{width: '24px', minWidth:'24px'}} >
            <CircleIcon  sx={{ fontSize: '8px',color: '#1195EB'  }} height='8px' ></CircleIcon> 
          </ListItemIcon>
          <ListItemText
            primary="Alle oder mehrere Leuchten in derselben Straße oder auf einer Kreuzung sind ausgefallen"
          />
        </ListItem>
        <ListItem sx={{padding:'0px'}}>
          <ListItemIcon sx={{width: '24px', minWidth:'24px'}} >
            <CircleIcon  sx={{ fontSize: '8px',color: '#1195EB'  }} height='8px' ></CircleIcon> 
          </ListItemIcon>
          <ListItemText
            primary="Ein Fußgängerüberweg ist nicht mehr beleuchtet"
          />
        </ListItem>
        <ListItem sx={{padding:'0px'}}>
          <ListItemIcon sx={{width: '24px', minWidth:'24px'}} >
            <CircleIcon  sx={{ fontSize: '8px',color: '#1195EB'  }} height='8px' ></CircleIcon> 
          </ListItemIcon>
          <ListItemText
            primary="Ein Straßenbeleuchtungsmast oder Schaltschrank wurde um-/angefahren"
          />
        </ListItem>
        <ListItem sx={{padding:'0px'}}>
          <ListItemIcon sx={{width: '24px', minWidth:'24px'}} >
            <CircleIcon  sx={{ fontSize: '8px',color: '#1195EB'  }} height='8px' ></CircleIcon> 
          </ListItemIcon>
          <ListItemText
            primary="Die Tür an einem Beleuchtungsmast ist offen und/oder Kabel liegen frei"
          />
        </ListItem>
      </List>
      <Box display="flex" flexDirection="column" textAlign={"center"}>
        <Typography>Dann wählen Sie bitte umgehend unsere kostenlose Hotline:</Typography>
        <a
          href="tel:+498003629477"
          style={{ color: theme.palette.error.main, textDecoration: "none", fontWeight: "bold", fontSize: "20px" }}
        >
          0800/3629 477
        </a>
      </Box>
    </Box>
  );
};

export default DangerHintBox;
