import { Typography } from "@mui/material";
import HintIcon from "./HintIcon";

export const HintInfoBox = ({ label }: { label: string }) => {
  return (
    <div className="flex flex-row border border-gray-200 h-6 justify-start bg-white">
      <div style={{ marginLeft: 5, alignSelf: "center" }}><HintIcon /></div>
      <Typography className="mx-1 self-center text-xs">
        {label}
      </Typography>
    </div>
  );
};
