import { DatePicker, TimePicker } from "@mui/lab";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isSameDay, isValid } from "date-fns";
import { useState } from "react";

export interface FaultRecognitionProps {
  onRecognitionDateTimeChange:(d: Date) => void;
}
export const FaultRecognition = ({ onRecognitionDateTimeChange }: FaultRecognitionProps) => {
  const [recognitionDate, setRecognitionDate] = useState<Date>(new Date());
  const [recognitionTime, setRecognitionTime] = useState<Date>(new Date());

  function handleOnDateChange(newDate?: Date | null) {
    if (!isValidDate(newDate, "Date")) return;
    setRecognitionDate(newDate);
    onRecognitionDateTimeChange(createDateTime(recognitionTime, newDate));
  };

  function handleOnTimeChange(newTime?: Date | null) {
    if (!isValidDate(newTime, "Time")) return;
    setRecognitionTime(newTime);
    onRecognitionDateTimeChange(createDateTime(newTime, recognitionDate));
  };

  return (
    <Box display="flex" flexDirection="column" gap="20px" alignItems="center">
      <Typography variant="h2" >
        Wann haben Sie die Störung bemerkt?
      </Typography>

      <Box display="flex" flexDirection="row" gap="20px">
        <DatePicker
          maxDate={new Date()}
          disableFuture
          label="Datum"
          value={recognitionDate}
          onChange={handleOnDateChange}
          renderInput={DateTimeTextField}
        />
        <TimePicker
          maxTime={isSameDay(new Date(), recognitionDate) ? new Date() : undefined}
          label="Uhrzeit"
          value={recognitionTime}
          onChange={handleOnTimeChange}
          renderInput={DateTimeTextField}
        />
      </Box>
    </Box>
  );
};

function isValidDate(d: any, dateType: "Date" | "Time"): d is Date {
  if (!d || !isValid(d)) {
    console.error("Invalid " + dateType, d);
    return false;
  }
  return true;
}

function createDateTime(time: Date, date: Date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds()
  );
}

const DateTimeTextField = (props: TextFieldProps) => <TextField {...props} size="small" />;

export default FaultRecognition;
