import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useAppContext } from "../../context/AppContext";
import { GenericErrorType, LionError, LionErrorType } from "../../models/LionError";
import { determineAPIBaseURL, xApiKey } from "../paths";

const axios = Axios.create({
  baseURL: determineAPIBaseURL(),
  headers: {
    "x-api-key": xApiKey,
    "content-type": "application/json",
  },
});

export function useApiClient() {
  const { isOnMaintenanceRef } = useAppContext();

  async function handleRequest<T>(
      errorType: LionErrorType,
      clbk: (...args: any[]) => Promise<AxiosResponse<T, any>>,
      ...args: any[]) 
  {
    if (isOnMaintenanceRef.current) {
      throw new LionError(GenericErrorType.MAINTENANCE);
    }

    try {
        return (await clbk(...args)).data;
      } catch (err) {
        if (isOnMaintenanceRef.current) {
          throw new LionError(GenericErrorType.MAINTENANCE);
        } else {
          throw new LionError(errorType, {
            title: "",
            message: (err as AxiosError)?.response?.statusText || "",
          });
        }
      }
  }

  return {
    get: async <T>(url: string, errorType: LionErrorType, config?: AxiosRequestConfig) => {
      return handleRequest<T>(errorType, axios.get, url, config);
    },
    post: async <T, B>(url: string, body: B, errorType: LionErrorType, config?: AxiosRequestConfig) => {
      return handleRequest<T>(errorType, axios.post, url, body, config);
    },
    put: async <T, B>(url: string, body: B, errorType: LionErrorType, config?: AxiosRequestConfig) => {
      return handleRequest<T>(errorType, axios.put, url, body, config);
    },
    remove: async <T>(url: string, errorType: LionErrorType, config?: AxiosRequestConfig) => {
      return handleRequest<T>(errorType, axios.delete, url, config);
    },
  };
}
