import { useRef } from "react";

export const useDebounce = () => {
  const timeoutId = useRef<number>();

  return function debounce<F extends (...params: any[]) => any>(fn: F, wait = 1000) {
    return function (this: any, args: typeof fn.arguments) {
      clearTimeout(timeoutId.current);
      timeoutId.current = window.setTimeout(() => {
        fn(args);
      }, wait);
    } as F;
  };
};
