import { Box } from "@mui/system";
import StartPageBackground from "../../assets/start-page-background.png";
import AddressForm from "./AddressForm";
import { useGeoMap } from "../../context/GeoMapContext";
import { useEffect } from "react";

export const Home = () => {
  const { customerScopeRef } = useGeoMap();

  useEffect(() => {
    customerScopeRef.current = undefined;
  }, [customerScopeRef])
  
  return (
    <>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        padding="50px"
        style={{ backgroundImage: `url(${StartPageBackground}`, backgroundSize: "cover" }}
      >
        <AddressForm />
      </Box>
    </>
  );
};

export default Home;
