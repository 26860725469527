import { Card, Typography } from "@mui/material";
import DangerHintBox from "./DangerHintBox";
import SearchBar from "./SearchBar";

export const AddressForm = () => {
  return (
    <>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "40px",
          maxWidth: "600px",
          height: "fit-content",
          gap: "70px",
          overflow: "hidden",
        }}
      >
        <Typography variant="h2"  textAlign="center">
          Vielen Dank, dass Sie eine Störung melden möchten. Wo befindet sich die Straßenleuchte?
        </Typography>
        <SearchBar />
        <DangerHintBox />
      </Card>
    </>
  );
};

export default AddressForm;
