import { FetchLightPointsQuery } from "../../api/lightPointsApi";
import { MAX_LIGHTPOINTS_ON_MAP } from "../../context/GeoMapContext";
import { LightPoint } from "../../models/LightPoint";

export namespace LightPointUtils {
  export const getLightPointAddress = (lightPoint: LightPoint): string => {
    const { zipCode, houseNumber: houseNo, street, city } = lightPoint;
    return [`${street ? street : ""}${houseNo ? " " + houseNo : ""}`, city, zipCode].filter((v) => !!v).join(", ");
  };

  export const isEqual = (l1: LightPoint, l2: LightPoint) => {
    if (l1.number === l2.number) {
      if (typeof l1.incident === typeof l2.incident) {
        if (l1.incident?.incidentStatus === l2.incident?.incidentStatus) {
          return true;
        }
      }
    }
    return false;
  };

  export const getComplement = (a: LightPoint[], b: LightPoint[]): LightPoint[] => {
    return a.filter((la) => !b.some((lb) => isEqual(la, lb)));
  };

  export const updateByNewLightPoints = (
      oldLightPoints: LightPoint[], 
      newLightPoints: LightPoint[], 
      maxLightPoints = MAX_LIGHTPOINTS_ON_MAP
  ): LightPoint[] => {
    const complement = getComplement(newLightPoints, oldLightPoints);
    if (complement.length + oldLightPoints.length > maxLightPoints) {
      oldLightPoints.length = maxLightPoints - complement.length;
    }
    return [...complement, ...oldLightPoints];
  }

  export const buildLightpointsQueryByMap = (map?: google.maps.Map): FetchLightPointsQuery | undefined => {
    if (!map) return;
    const zoom = map.getZoom(),
      ceLatitude = map.getCenter()?.lat(),
      ceLongitude = map.getCenter()?.lng(),
      neLatitude = map.getBounds()?.getNorthEast().lat(),
      neLongitude = map.getBounds()?.getNorthEast().lng(),
      swLatitude = map.getBounds()?.getSouthWest().lat(),
      swLongitude = map.getBounds()?.getSouthWest().lng();
    if (neLatitude && neLongitude && swLatitude && swLongitude) {
      return {
        neLatitude,
        neLongitude,
        swLatitude,
        swLongitude,
      };
    }
    if (zoom && ceLatitude && ceLongitude) {
      return { zoom, ceLatitude, ceLongitude };
    }
  };
}
