import { memo, useState } from "react";
import { InfoWindow } from "./InfoWindow";
import { Marker as GoogleMapsMarker } from "@react-google-maps/api";
import { LightPoint } from "../../models/LightPoint";
import { useGeoMap } from "../../context/GeoMapContext";
import { colors } from "../../theme/theme";
import { IncidentStatus, isIncidentClosed, isIncidentRequiredApproval } from "../../models/IncidentStatus";

interface Props {
  lightingPoint: LightPoint;
  onShowFaultReport?: (incidentUuid: string) => void;
}

export const Marker = memo(({ lightingPoint, onShowFaultReport }: Props) => {
  const [hover, toggleHoverEffect] = useState(false);
  const { setSelectedMarker, selectedMarker } = useGeoMap();

  if (lightingPoint.latitude == null || lightingPoint.longitude == null) {
    return null;
  }

  function handleOnClick() {
    setSelectedMarker(lightingPoint);
  }

  function handleOnMouseOver() {
    toggleHoverEffect(true);
  }

  function handleOnMouseOut() {
    toggleHoverEffect(false);
  }

  function handleOnCloseInfowWindow() {
    setSelectedMarker(undefined);
  }

  return (
    <>
      <GoogleMapsMarker
        position={{ lat: lightingPoint.latitude, lng: lightingPoint.longitude }}
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: hover ? 4 : 3,
          fillColor: determineColorFromState(lightingPoint?.incident?.incidentStatus),
          strokeColor: determineColorFromState(lightingPoint?.incident?.incidentStatus),
          fillOpacity: 1,
          strokeOpacity: 1
        }}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        onClick={handleOnClick}
      />

      {(hover || selectedMarker?.number === lightingPoint.number) && (
        <InfoWindow
          lightPoint={lightingPoint}
          onClose={handleOnCloseInfowWindow}
          incident={lightingPoint?.incident}
          lat={lightingPoint.latitude}
          lng={lightingPoint.longitude}
          onShowFaultReport={onShowFaultReport}
        />
      )}
    </>
  );
});

const determineColorFromState = (state?: IncidentStatus): string => {
  if (!state) return colors.status.completed;

  if (isIncidentRequiredApproval(state))
    return colors.status.approvalPending;
  if (isIncidentClosed(state))
    return colors.status.completed;
  return colors.status.inProgress;
};

export default Marker;
