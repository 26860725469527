import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  CircularProgress,
  Typography,
  Alert,
  Collapse,
  IconButton,
  AlertColor,
  Button,
  ButtonProps,
  Box,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useGeoMap } from "../../context/GeoMapContext";
import { LightPoint } from "../../models/LightPoint";
import { Autocomplete, GoogleSearchItemResult } from "../../parts/Autocomplete";
import { Utils } from "../../utils";
import { LightPointSelectDialog } from "./LightPointSelectDialog/LightPointSelectDialog";
import { LightPointUtils } from "../../utils/lightPointUtils/lightPointUtils";

const GEOCODER_COMPONENT_RESTRICTION = { country: "de" };

interface GeoSearchBarProps {
  title?: string;
  layout?: "row" | "column";
}

export const GeoSearchBar = (props: GeoSearchBarProps) => {
  const {
    fetchLightPointsByNumber,
    fetchLightPoints,
    mapRef,
    setSelectedMarker,
    selectedMarker,
    locality,
    fitMapBounds,
  } = useGeoMap();
  const autocompleteService = useRef<google.maps.places.AutocompleteService>(new google.maps.places.AutocompleteService());
  const geocoder = useRef<google.maps.Geocoder>(new google.maps.Geocoder());
  const [searchOptions, setSearchOptions] = useState<GoogleSearchItemResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [lightingNo, setLightingNo] = useState("");
  const [showNoLightPointsToast, setShowNoLightPointsToast] = useState(false);
  const [lightPointsToSelect, setLightPointsToSelect] = useState<LightPoint[]>([]);

  const handleOnSelectLightPoint = (lightPoint: LightPoint) => {
    const map = mapRef.current;
    if (!map) return;
    setLightPointsToSelect([]);
    setSelectedMarker(lightPoint);
    fitMapBounds({ lightPoints: [lightPoint] });
  };

  async function getLightPointsByNumber() {
    if (!lightingNo) return [] as LightPoint[];
    const formatedLightingNo = lightingNo.padStart(5, "0");
    setLightingNo(formatedLightingNo);
    const lightPoints = await fetchLightPointsByNumber(formatedLightingNo);
    if (!lightPoints?.length) {
      setShowNoLightPointsToast(true);
      return null;
    } else if (lightPoints.length > 1) {
      setLightPointsToSelect(lightPoints);
      return null;
    }
    return lightPoints;
  }

  useEffect(() => {
    const input = locality ? locality + ", " + address : address;
    const request: google.maps.places.AutocompletionRequest = {
      input,
      componentRestrictions: GEOCODER_COMPONENT_RESTRICTION,
      types: ["address"],
    };

    autocompleteService.current.getPlacePredictions(request, (results) => {
      setSearchOptions(results?.map((prediction) => prediction) || []);
    });
  }, [address, locality]);

  const geocodeAddress = async (address: string): Promise<google.maps.GeocoderResult | undefined> => {
    if (!Utils.hasText(address)) return;
    try {
      const geocoderResponce = await geocoder.current.geocode({ address });
      const geocoderResult = geocoderResponce.results.shift();
      if (!geocoderResult) {
        console.error("No geographical information could be retrieved for the given address", address);
        return;
      }
      return geocoderResult;
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const map = mapRef.current;
    if (!map) return;
    setShowNoLightPointsToast(false);
    setIsLoading(true);
    const geocoderResult = lightingNo ? undefined : await geocodeAddress(address);
    const addressViewport = getCustomStreetViewport(geocoderResult);
    const lightPoints = await (!lightingNo && !address ? fetchLightPoints() : getLightPointsByNumber());
    setIsLoading(false);
    if (lightPoints === null) return;
    if (lightPoints.length === 1) {
      setSelectedMarker(lightPoints[0]);
    }
    fitMapBounds({ addressViewport, lightPoints });
  };

  useEffect(() => {
    let lightingNr = "", address = "";
    if (selectedMarker && !selectedMarker.incident) {
      lightingNr = selectedMarker.number;
      address = LightPointUtils.getLightPointAddress(selectedMarker);
    }
    setLightingNo(lightingNr);
    setAddress(address);
  }, [selectedMarker]);

  const renderSearchButtonContent = () => {
    if (isLoading) {
      return <CircularProgress color="secondary" style={{ width: 24, height: 24 }} />;
    } else if (!address && !lightingNo) {
      return "Erneut laden";
    } else {
      return "Suche starten";
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSearchClick(e)} style={{ width: "100%", maxWidth: "1000px" }}>
        {props.title && (
          <Typography variant="h5" color="black" sx={{ marginY: "20px" }}>
            {props.title}
          </Typography>
        )}
        {selectedMarker?.incident && (
          <Alert severity="info" sx={{ marginY: "20px" }}>
            Störung anlegen nicht möglich!
            <br />
            Es existiert bereits eine Störung.
          </Alert>
        )}
        <Box
          display="flex"
          flexDirection={props.layout ?? "column"}
          justifyContent="space-between"
          gap="20px"
          marginBottom={"20px"}
        >
          <Autocomplete
            label="Adresse"
            placeholder="Straßenname ggf. Hausnr."
            value={address}
            options={searchOptions || []}
            onChange={setAddress}
            id={"searchbar-address-autocomplete"}
            className="w-7/12"
          />
          <TextField
            fullWidth
            label="Leuchtstelle"
            value={lightingNo}
            onChange={(e) => setLightingNo(e.target.value)}
            placeholder="Leuchtstelle suchen"
            inputProps={{ pattern: "^[A-Z]{5}-(SOLAR|PRIVA|\\d{5})-\\d{5}$" }}
            className="w-3/12"
          />
          <CreateFaultReportButton className="w-2/12" type="submit">
            {renderSearchButtonContent()}
          </CreateFaultReportButton>
        </Box>
        <ClosableToast
          onClose={() => setShowNoLightPointsToast(false)}
          open={showNoLightPointsToast}
          text="Keine Leuchtstellen gefunden"
          variant="error"
        />
      </form>
      <LightPointSelectDialog
        onClose={() => setLightPointsToSelect([])}
        lightPoints={lightPointsToSelect}
        onSelect={handleOnSelectLightPoint}
      />
    </>
  );
};

function getCustomStreetViewport(geocoderResult?: google.maps.GeocoderResult) {
  if (geocoderResult === undefined) return;
  const location = geocoderResult.geometry.location;
  const distanceAwayFromLocation = 0.0009;
  const nordWest: google.maps.LatLngLiteral = {
    lat: location.lat() + distanceAwayFromLocation,
    lng: location.lng() - distanceAwayFromLocation,
  };
  const southEast: google.maps.LatLngLiteral = {
    lat: location.lat() - distanceAwayFromLocation,
    lng: location.lng() + distanceAwayFromLocation,
  };
  const customAddressViewport = new google.maps.LatLngBounds().extend(nordWest).extend(southEast);
  return customAddressViewport;
}

interface ClosableToastProps {
  open: boolean;
  variant: AlertColor;
  onClose: () => void;
  text: string;
}

function ClosableToast(props: ClosableToastProps) {
  return (
    <Collapse in={props.open}>
      <Alert
        severity={props.variant}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={props.onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {props.text}
      </Alert>
    </Collapse>
  );
}

export const CreateFaultReportButton = (props: ButtonProps) => (
  <Button variant="contained" {...props} size="large" sx={{ ...props.sx, width: "clamp(8rem, 100%, 10rem)" }} />
);
