import { MutableRefObject } from "react";
import { LightPointErrorType } from "../../models/LionError";
import { useApiClient } from "../apiClient/apiClient";
import { getParamsURLEncoded } from "../apiParamFactory";
import { FetchLightPointsQuery, GetLightPointsResponse } from "./lightPointsApi.types";

export const useLightPointsApi = (customerScopeRef: MutableRefObject<string|undefined>) => {
  const apiClient = useApiClient();

  async function getLightPoints(
    query?: FetchLightPointsQuery,
  ): Promise<GetLightPointsResponse> {
    const searchParams = getParamsURLEncoded(query);
    return apiClient.get<GetLightPointsResponse>(
      `v2/lightingpoints/by-bounds?${searchParams.toString()}`, LightPointErrorType.FETCH_LIGHTPOINTS
    );
  }

  const searchLightPoints = (lightingNo?: string) => apiClient.get<GetLightPointsResponse>(
    "v2/lightingpoints/search?" + getParamsURLEncoded({ lightingNo }, customerScopeRef.current).toString(),
    LightPointErrorType.FETCH_LIGHTPOINTS
  );

  return {
    getLightPoints,
    searchLightPoints,
  };
};
