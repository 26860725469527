import { useMemo } from "react";
import { useLocation } from "react-router";

export function useDefaultCenter() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const defaultLatitude = Number(query.get("lat"));
  const defaultLongitude = Number(query.get("lng"));

  const defaultCenter = useMemo(
    () => ({ lat: Number(defaultLatitude), lng: Number(defaultLongitude) }),
    [defaultLatitude, defaultLongitude]
  );

  return {
    defaultCenter,
    defaultLatitude,
    defaultLongitude
  }

}