import { Box, Typography } from "@mui/material";
import React from "react";
import { CreateFaultReportButton } from "../GeoSearchBar";
import { StepperBar } from "./StepperBar";

interface StepperProps {
  onActiveStepChange: (step: number) => void;
  activeStep: number;
  title?: string;
  steps: StepInfo[];
  style?: React.CSSProperties;
}

interface StepInfo {
  label: string;
  icon: JSX.Element;
  content: JSX.Element;
  /**
   * @default "Weiter"
   */
  submitBtnLabel?: string;
  /**
   * @default "Zurück"
   */
  secondaryBtnLabel?: string;
  /**
   * 
   * @default onActiveStepChange(activeStep + 1)
   */
  onSubmit?: () => void;
  /**
   * 
   * @default onActiveStepChange(activeStep - 1)
   */
  onSecondaryAction?: () => void;
  disableSubmit?: boolean;
}

export function Stepper({ activeStep, steps, ...props}: StepperProps) {
  const stepBarSteps = steps.map(s => ({ label: s.label, icon: s.icon }));

  function handleClickNext() {
    const step = steps[activeStep];
    if (step.onSubmit) {
  
      step.onSubmit();
    } else {
      props.onActiveStepChange(activeStep + 1);
    }
  }

  function handleOnSecondaryAction() {
    const step = steps[activeStep];
    if (step.onSecondaryAction) {
      step.onSecondaryAction();
    } else {
      props.onActiveStepChange(activeStep - 1);
    }
  }

  return (
    <>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        {props.title}
      </Typography>
      <Box style={props.style}>
        <StepperBar 
          index={activeStep}
          steps={stepBarSteps}
        />
        {steps[activeStep].content}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
         
          }}
        >
          <CreateFaultReportButton
            sx={{
              minWidth: "220px"
            }}
            className="my-2"
            color="primary"
            disabled={steps[activeStep].disableSubmit}
            onClick={handleClickNext}
          >
            {steps[activeStep].submitBtnLabel || "Weiter"}
          </CreateFaultReportButton>
          {!steps[activeStep].onSecondaryAction ? null : ( 
            <CreateFaultReportButton
              sx={{
                minWidth: "220px"
              }}
              color="secondary" 
              variant="outlined" 
              onClick={handleOnSecondaryAction}
            >
              {steps[activeStep].secondaryBtnLabel || "Zurück"}
            </CreateFaultReportButton>
          )}
        </Box>
      </Box>
    </>
  );
}