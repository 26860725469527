import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/ErrorOutline";

interface LightPointsErrorBoxProps {
  onClose: () => void;
}

export const LightPointsErrorBox = ({ onClose }: LightPointsErrorBoxProps) => {
  return (
    <Paper className="w-full absolute z-10 flex items-center justify-center bg-gray-500 bg-opacity-30 h-full">
      <Paper className="bg-white w-4/6 max-w-4xl">
        <Box className="flex justify-between items-center p-3">
          <Box className="flex items-center">
            <ErrorIcon color="error" fontSize="large" className="mr-3" />
            <Typography variant="h4" className="font-bold text-black">
              Anzeige der Leuchtstellen aktuell leider nicht möglich
            </Typography>
          </Box>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box className="p-5">
          <Typography>
            Aufgrund eines technischen Problems können die Leuchtstellen aktuell leider nicht angezeigt werden. Bitte
            versuchen Sie es später noch einmal. Wir bitten um Ihr Verständnis.
          </Typography>
          <br />
          <br />
          <Typography>Ihr #LightOn-Team der Netze BW GmbH</Typography>
        </Box>
      </Paper>
    </Paper>
  );
};
