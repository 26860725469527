export enum IncidentStatus {
  APPROVAL_REQUIRED_BY_COMMUNE = "APPROVAL_REQUIRED_BY_COMMUNE",
  APPROVAL_REQUIRED_BY_NETZE_BW = "APPROVAL_REQUIRED_BY_NETZE_BW",
  NEW = "NEW",
  ORDER_PLACED = "ORDER_PLACED",
  OVERDUE = "OVERDUE",
  CHECK_FEEDBACK = "CHECK_FEEDBACK",
  //ORDER_SCHEDULED                         = "ORDER_SCHEDULED",
  CHECK_FEEDBACK_FOLLOW_UP_MEASURES = "CHECK_FEEDBACK_FOLLOW_UP_MEASURES",
  INTERNAL_CLARIFICATION = "INTERNAL_CLARIFICATION",
  FOLLOW_UP_MEASURES_CABLE_TEST_VAN = "FOLLOW_UP_MEASURES_CABLE_TEST_VAN",
  FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT = "FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT",
  FOLLOW_UP_MEASURES_MORE_DEFICIENCIES = "FOLLOW_UP_MEASURES_MORE_DEFICIENCIES",
  FOLLOW_UP_MEASURE_IN_PROGRESS = "FOLLOW_UP_MEASURE_IN_PROGRESS",
  IMMINENT_DANGER = "IMMINENT_DANGER",
  IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT = "IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT",
  UNREPAIRABLE = "UNREPAIRABLE",
  REJECTED_BY_COMMUNE = "REJECTED_BY_COMMUNE",
  REJECTED_BY_NETZE_BW = "REJECTED_BY_NETZE_BW",
  FAULT_FIXED = "FAULT_FIXED",
  FAULT_FIXED_PROVISIONAL_SOLUTION = "FAULT_FIXED_PROVISIONAL_SOLUTION",
  POLE_NOT_ACCESSIBLE = "POLE_NOT_ACCESSIBLE",
  TRANSMITTED_TO_CONTROL_CENTER = "TRANSMITTED_TO_CONTROL_CENTER",
  DELETED = "DELETED",
  ORDER_MATERIAL_OPEN = "ORDER_MATERIAL_OPEN",
  ORDER_MATERIAL_CLOSED = "ORDER_MATERIAL_CLOSED"
};

export const IncidentStatusLabel: Record<IncidentStatus, string> = {
  [IncidentStatus.APPROVAL_REQUIRED_BY_COMMUNE]: "Freigabe erforderlich",
  [IncidentStatus.APPROVAL_REQUIRED_BY_NETZE_BW]: "Störungsbehebung vorbereiten",
  [IncidentStatus.NEW]: "Meldung übermittelt",
  [IncidentStatus.ORDER_PLACED]: "Störungsbehebung beauftragt",
  [IncidentStatus.OVERDUE]: "Störungsbehebung eingeplant",
  [IncidentStatus.CHECK_FEEDBACK]: "Störungsaufnahme erfolgt",
  //[IncidentStatus.ORDER_SCHEDULED]: "Störungsbehebung eingeplant",
  [IncidentStatus.CHECK_FEEDBACK_FOLLOW_UP_MEASURES]: "Durchführung Folgemaßnahmen",
  [IncidentStatus.INTERNAL_CLARIFICATION]: "Folgemaßnahmen erforderlich",
  [IncidentStatus.FOLLOW_UP_MEASURES_CABLE_TEST_VAN]: "Folgemaßnahmen (Kabelmesswagen)",
  [IncidentStatus.FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT]: "Folgemaßnahmen (Materialbeschaffung)",
  [IncidentStatus.FOLLOW_UP_MEASURES_MORE_DEFICIENCIES]: "Folgemaßnahmen (weitere Mängel)",
  [IncidentStatus.FOLLOW_UP_MEASURE_IN_PROGRESS]: "Durchführung Folgemaßnahmen",
  [IncidentStatus.IMMINENT_DANGER]: "Gefahr im Verzug - Erstsicherung durchgeführt",
  [IncidentStatus.IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT]: "Gefahr im Verzug - Angebot Instandsetzung",
  [IncidentStatus.UNREPAIRABLE]: "Kontaktaufnahme durch Fachvertrieb",
  [IncidentStatus.REJECTED_BY_COMMUNE]: "Abgelehnt durch Kommune",
  [IncidentStatus.REJECTED_BY_NETZE_BW]: "Abgelehnt durch Netze BW",
  [IncidentStatus.FAULT_FIXED]: "Störung behoben",
  [IncidentStatus.FAULT_FIXED_PROVISIONAL_SOLUTION]: "Störung behoben",
  [IncidentStatus.POLE_NOT_ACCESSIBLE]: "Mast nicht zugänglich (Folgemaßnahmen Kommune)",
  [IncidentStatus.TRANSMITTED_TO_CONTROL_CENTER]: "Falscher Meldeweg",
  [IncidentStatus.DELETED]: "Gelöscht",
  [IncidentStatus.ORDER_MATERIAL_OPEN]: "Material wurde bestellt",
  [IncidentStatus.ORDER_MATERIAL_CLOSED]: "Material wurde geliefert"
};

export function isIncidentClosed(status: IncidentStatus): boolean {
  return [
    IncidentStatus.POLE_NOT_ACCESSIBLE,
    IncidentStatus.REJECTED_BY_NETZE_BW,
    IncidentStatus.REJECTED_BY_COMMUNE,
    IncidentStatus.TRANSMITTED_TO_CONTROL_CENTER,
    IncidentStatus.FAULT_FIXED,
    IncidentStatus.FAULT_FIXED_PROVISIONAL_SOLUTION,
    IncidentStatus.DELETED
  ].includes(status);
};

export function isIncidentInProgress(status: IncidentStatus): boolean {
  return [
    IncidentStatus.APPROVAL_REQUIRED_BY_NETZE_BW,
    IncidentStatus.NEW,
    IncidentStatus.ORDER_PLACED,
    IncidentStatus.OVERDUE,
    IncidentStatus.CHECK_FEEDBACK,
    IncidentStatus.CHECK_FEEDBACK_FOLLOW_UP_MEASURES,
    IncidentStatus.INTERNAL_CLARIFICATION,
    IncidentStatus.FOLLOW_UP_MEASURES_CABLE_TEST_VAN,
    IncidentStatus.FOLLOW_UP_MEASURES_MATERIAL_PROCUREMENT,
    IncidentStatus.FOLLOW_UP_MEASURES_MORE_DEFICIENCIES,
    IncidentStatus.FOLLOW_UP_MEASURE_IN_PROGRESS,
    IncidentStatus.IMMINENT_DANGER,
    IncidentStatus.IMMINENT_DANGER_LIES_AT_SALES_DEPARTMENT,
    IncidentStatus.UNREPAIRABLE,
    IncidentStatus.ORDER_MATERIAL_OPEN,
    IncidentStatus.ORDER_MATERIAL_CLOSED
  ].includes(status);
}
export function isIncidentRequiredApproval(status: IncidentStatus): boolean {
  return [
    IncidentStatus.APPROVAL_REQUIRED_BY_COMMUNE
  ].includes(status);
};
