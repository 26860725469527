import { Box } from "@mui/system";
import { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FaultRecognition, { FaultRecognitionProps } from "./FaultRecognition";
import { FaultSymptoms, SymptomSelectBoxProps } from "./FaultSymptoms";

type FaultDetailsStepProps = SymptomSelectBoxProps & FaultRecognitionProps;
export const FaultDetailsStep = ({onRecognitionDateTimeChange ,...symptomProps}: FaultDetailsStepProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }

    await executeRecaptcha("submit_fault_report");
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="60px">
      <FaultSymptoms {...symptomProps} />
      <FaultRecognition onRecognitionDateTimeChange={onRecognitionDateTimeChange}/>
    </Box>
  );
};

export default FaultDetailsStep;
