import { useEffect, useState } from "react";
import { useMaintenanceReports } from "../../api/maintenanceApi/useMaintenanceReports";
import { useAppContext } from "../../context/AppContext";
import { MaintenanceReport } from "../../models/MaintenanceReport";
import { MaintenanceUtils } from "../../utils/maintenanceUtils";

export interface MaintenanceReportsValue {
  maintenance?: MaintenanceReport;
  maintenanceFetched: boolean;
}

interface MaintenanceReportsProps {
  children: (v: MaintenanceReportsValue) => JSX.Element;
}

export function MaintenanceReports({ children }: MaintenanceReportsProps) {
  const { isOnMaintenanceRef } = useAppContext();
  const [maintenanceFetched, setMaintenanceFetched] = useState(false);
  const [maintenance, setMaintenance] = useState<MaintenanceReport | undefined>();

  const { data: reports, isError } = useMaintenanceReports();

  useEffect(() => {
    if (isError) {
      if (!maintenanceFetched) {
        setMaintenanceFetched(true);
      }
    }
  }, [isError, maintenanceFetched, setMaintenanceFetched]);

  useEffect(() => {
    if (!reports) return;
    const maintenance = MaintenanceUtils.getCurrentMaintenance(reports, new Date());
    setMaintenance(maintenance);
    isOnMaintenanceRef.current = maintenance !== undefined;
    if (!maintenanceFetched) {
      setMaintenanceFetched(true);
    }
  }, [reports, isOnMaintenanceRef, maintenanceFetched, setMaintenanceFetched]);

  if (typeof children !== "function") return null;

  return children({ maintenance, maintenanceFetched });
}