import React, { useCallback, useMemo, useRef, useState } from "react";
import { GeoMapContextValue, GeoMapContextProviderProps, FitMapBoundsRequest } from "./GeoMapContext.types";
import { useJsApiLoader } from "@react-google-maps/api";
import { LightPoint } from "../../models/LightPoint";
import { FetchLightPointsQuery, useLightPointsApi } from "../../api/lightPointsApi";
import { GenericErrorType, isLionError } from "../../models/LionError";
import { LightPointUtils } from "../../utils/lightPointUtils/lightPointUtils";
import { FullscreenSpinner } from "../../parts";

const initState: GeoMapContextValue = {
  allLightPoints: [],
  setSelectedMarker: () => {},
  mapRef: { current: undefined },
  mapIsLoading: false,
  setMapIsLoading: () => {},
  fetchLightPoints: async () => [],
  isOnMoveMapEffectsBlocked: { current: false },
  center: { current: { lat: 0, lng: 0 } },
  fetchLightPointsByNumber: async (_) => [],
  lightPointsError: false,
  customerScopeRef: { current: undefined },
  fitMapBounds: () => {},
};

export const MAX_LIGHTPOINTS_ON_MAP = 500;

export const GeoMapContext = React.createContext<GeoMapContextValue>(initState);

export const GeoMapProvider = (props: GeoMapContextProviderProps) => {
  const [selectedMarker, setSelectedMarker] = useState<LightPoint>();
  const [allLightPoints, setAllLightPoints] = useState<LightPoint[]>([]);
  const [mapIsLoading, setMapIsLoading] = useState(false);
  const center = useRef<google.maps.LatLngLiteral>(props.defaultCenter);
  const [locality, setLocality] = useState<string>();
  const mapRef = useRef<google.maps.Map>();
  const isOnMoveMapEffectsBlocked = useRef(false);
  const [lightPointsError, setLightPointsError] = useState(false);
  const customerScopeRef = useRef<string>();
  const { getLightPoints, searchLightPoints } = useLightPointsApi(customerScopeRef);

  const { isLoaded: googleApiIsLoaded } = useJsApiLoader({
    googleMapsApiKey: props.googleMapsApiKey,
    libraries: props.googleMapslibraries,
  });

  const getCitiesFromGeocoderResponce = useCallback(
    (resp: google.maps.GeocoderResponse) =>
      resp.results
        .map((r) => r.address_components.filter((a) => a.types.includes("administrative_area_level_4")))
        .filter((a) => a.length > 0),
    []
  );

  const findAndSetLocality = useCallback(() => {
    if (!center.current || !googleApiIsLoaded) return;
    const geocoder = new google.maps.Geocoder();
    const { lat, lng } = center.current;
    geocoder
      .geocode({
        location: new google.maps.LatLng({ lat, lng }),
      })
      .then((res) => {
        const citiesList = getCitiesFromGeocoderResponce(res);
        const firstCity = citiesList.shift()?.shift()?.long_name;
        setLocality(firstCity);
      });
  }, [googleApiIsLoaded, getCitiesFromGeocoderResponce]);

  const fetchLightPointsByNumber: (lightingNo: string) => Promise<LightPoint[]> = useMemo(
    () => async (lightingNo: string) => {
      if (!lightingNo) return [];
      setMapIsLoading(true);
      const lightPointQueryResult = await searchLightPoints(lightingNo);
      const newLightPoints = LightPointUtils.updateByNewLightPoints(allLightPoints, lightPointQueryResult.data);
      setAllLightPoints(newLightPoints);
      setMapIsLoading(false);
      return lightPointQueryResult.data;
    },
    [allLightPoints, searchLightPoints]
  );

  const fetchLightPoints = useMemo(
    () =>
      async (query?: FetchLightPointsQuery): Promise<LightPoint[]> => {
        if (!query && center && props.defaultFetchType === "center") {
          query = {
            ceLatitude: center.current.lat,
            ceLongitude: center.current.lng,
          };
        }
        try {
          setMapIsLoading(true);
          const { data: lightPoints, ...res } = await(!query ? searchLightPoints() : getLightPoints(query));
          if (lightPointsError) {
            setLightPointsError(false);
          }
          const firstLightPoint = lightPoints[0];
          if (center.current === props.defaultCenter) {
            center.current = {
              lat: firstLightPoint?.latitude || res.centerLatitude,
              lng: firstLightPoint?.longitude || res.centerLongitude,
            };
            findAndSetLocality();
          }
          if (!customerScopeRef.current) {
            customerScopeRef.current = res.customerScope || firstLightPoint.customerScope;
          }
          let newLightPoints = lightPoints;
          if (newLightPoints.length > MAX_LIGHTPOINTS_ON_MAP) {
            newLightPoints.length = MAX_LIGHTPOINTS_ON_MAP;
          }
          if (selectedMarker) {
            newLightPoints.unshift();
            newLightPoints.push(selectedMarker);
          }
          setAllLightPoints(newLightPoints);
          return newLightPoints;
        } catch (e) {
          if (!isLionError(e) || e.errorType !== GenericErrorType.MAINTENANCE) {
            setLightPointsError(true);
            console.log("Error Fetching LightPoints", e);
            setAllLightPoints([]);
          }
          return [];
        } finally {
          setMapIsLoading(false);
        }
      },
    [
      getLightPoints, 
      findAndSetLocality, 
      lightPointsError, 
      props.defaultCenter, 
      props.defaultFetchType, 
      selectedMarker, 
      searchLightPoints,
    ]
  );

  /**
   * Fits the map bounds, center and zoom based on a list of locations
   * @param map
   * @param places
   */
  const fitMapBounds = useCallback(
    (request: FitMapBoundsRequest): void => {
      const map = mapRef.current;
      if (!map) {
        return;
      }
      let bounds: google.maps.LatLngBounds;
      if (request.lightPoints?.length) {
        bounds = new google.maps.LatLngBounds();
        request.lightPoints.forEach(({ latitude, longitude }) => {
          if (latitude && longitude) {
            bounds.extend(new google.maps.LatLng(latitude, longitude));
          }
        });
      } else if (request.addressViewport) {
        bounds = request.addressViewport;
      } else {
        return console.warn("fitMapBounds needs either lightPoints or addressViewport to fit map bounds");
      }
      map.fitBounds(bounds, 10);
      if (!request.lightPoints || request.lightPoints.length < MAX_LIGHTPOINTS_ON_MAP) {
        fetchLightPoints(LightPointUtils.buildLightpointsQueryByMap(map));
      }
    },
    [fetchLightPoints]
  );

  const contextValue = useMemo(
    () => ({
      selectedMarker,
      setSelectedMarker,
      allLightPoints,
      mapRef,
      fetchLightPoints,
      mapIsLoading,
      setMapIsLoading,
      isOnMoveMapEffectsBlocked,
      locality,
      fetchLightPointsByNumber,
      center,
      lightPointsError,
      customerScopeRef,
      fitMapBounds,
    }),
    [
      selectedMarker,
      allLightPoints,
      fetchLightPoints,
      mapIsLoading,
      locality,
      fetchLightPointsByNumber,
      lightPointsError,
      fitMapBounds,
    ]
  );

  return !googleApiIsLoaded ? <FullscreenSpinner/> : (
    <GeoMapContext.Provider value={contextValue}>{props.children}</GeoMapContext.Provider>
  );
};

export const sameLightingNo = (n1: string, n2: string) => {
  const paramsSorted = [n1, n2].sort((a, b) => a.length - b.length);
  n1 = paramsSorted[0];
  n2 = paramsSorted[1];
  n2 = n2.slice(n2.length - n1.length, n2.length);
  return n1 === n2;
};

export const useGeoMap = () => {
  return React.useContext(GeoMapContext);
};
