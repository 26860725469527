export const xApiKey = "GooRDiKrSj5eevcvjecCnaB3wTaVqllK3ShNF6rd";

export function determineAPIBaseURL() {
  const hostname = window.location.hostname;
  if (hostnames.dev.includes(hostname)) {
    return baseUrl.dev;
  } else if (hostnames.stage.includes(hostname)) {
    return baseUrl.stage;
  } else if (hostnames.prod.includes(hostname)) {
    return baseUrl.prod;
  }
  console.error("Not found basePath for the hostname: ", hostname);
  return "";
}

export const hostnames = {
  dev: ["localhost", "lion-app.local", "lion-dev.dienstleistungen.netze-bw.de", "d3ppcel7z9kuos.cloudfront.net", "lion-dev.oeffentlich.dienstleistungen.netze-bw.de", "buefostack-websitebucket75c24d94-5njhemow7yyp.s3-website.eu-central-1.amazonaws.com"],
  stage: ["lion-stage.dienstleistungen.netze-bw.de", "d2o8e1vneqao03.cloudfront.net", "lion-stage.oeffentlich.dienstleistungen.netze-bw.de"],
  prod: ["lion.dienstleistungen.netze-bw.de", "d9tkvgxy54dvq.cloudfront.net", "lion.oeffentlich.dienstleistungen.netze-bw.de"],
};

export const baseUrl = {
  dev: "https://api.lion-dev.oeffentlich.dienstleistungen.netze-bw.de",
  stage: "https://api.lion-stage.oeffentlich.dienstleistungen.netze-bw.de",
  prod: "https://api.lion.oeffentlich.dienstleistungen.netze-bw.de",
};
