import { createContext, Dispatch, MutableRefObject, PropsWithChildren, SetStateAction, useContext, useMemo, useRef, useState } from "react";

interface AppContextValue {
  isOnMaintenanceRef: MutableRefObject<boolean>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const initValue: AppContextValue = {
  isOnMaintenanceRef: { current: false },
  isLoading: false,
  setIsLoading: () => false,
};

const AppContext = createContext(initValue);

export function AppContextProvider(props: PropsWithChildren<{}>) {
  const isOnMaintenanceRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const value: AppContextValue = useMemo(() => ({
    isOnMaintenanceRef,
    isLoading,
    setIsLoading,
  }), [isLoading]);

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}

export const useAppContext = () => useContext(AppContext);