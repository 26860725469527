import { Card, Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { colors } from "../theme/theme";

interface Props {
  variant?: "outlined" | "elevation";
  customContent?: ReactNode;
  customTitle?: string;
  customIcon?: ReactNode;
  style?: React.CSSProperties;
  detailedContent?: boolean;
  phoneNumberInfo: boolean;
}

export const EmergencyHintBox = (props: Props) => {
  return (
    <Card
      variant={ props.variant || "outlined" }
      sx={{ padding: "12px", display: "flex", border: "solid 3px rgba(220, 38, 38,1)" }}
      style={ props.style }
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 1 }}>
          <Box marginRight="10px">{ props.customIcon }</Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>
              { props.customTitle }
            </Typography>
          </Box>
        </Box>
        <Box>
          { props.customContent ? (
            props.customContent
          ) : (
            <Typography>
              Bei Störungen in der Straßenbeleuchtung, die eine Gefahr darstellen, wählen Sie bitte umgehend unsere
              Hotline
            </Typography>
          )}
          { props.phoneNumberInfo && (
            <Typography marginTop={"5px"}>
              <a
                href="tel:+498003629477"
                style={{ color: colors.text.primary, textDecoration: "none", fontWeight: "bold" }}
              >
                0800/3629 477
              </a>
            </Typography>
          )}
        </Box>
        {props.detailedContent && (
          <>
            <br />
            <Typography style={{ fontSize: 14 }}>
              <strong>Wann liegt eine Gefahr vor?</strong>
            </Typography>
            <ul>
              <Typography style={{ fontSize: 14 }}>
                <li>Alle oder mehrere Leuchten in derselben Straße oder auf einer Kreuzung sind ausgefallen</li>
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                <li>Ein Fußgängerüberweg ist nicht mehr beleuchtet</li>
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                <li>Ein Straßenbeleuchtungsmast oder Schaltschrank wurde um-/angefahren</li>
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                <li>Die Tür an einem Beleuchtungsmast ist offen und/oder Kabel liegen frei</li>
              </Typography>
            </ul>
          </>
        )}
      </Box>
    </Card>
  );
};
