import format from "date-fns/fp/formatWithOptions";
import { de } from "date-fns/locale";
import { useMemo } from "react";

const formatDate = format({locale: de}, "dd.MM.yyyy HH:mm");

export const UtcToLocale = ({ datetime }: { datetime?: string }): JSX.Element => {

  const localDate = useMemo(() => {
    if (datetime) {
      return formatDate(new Date(datetime));
    }
  }, [datetime]);

  if (!datetime) {
    return <>-</>;
  }

  // check if datetime string already has trailing Z
  if (!datetime.endsWith("Z")) {
    datetime = datetime.concat("Z");
  }

  let theDate = new Date(datetime);
  if (!(theDate instanceof Date)) {
    return <>-</>;
  }

  return <>{localDate}</>;
};

export default UtcToLocale;
