import { createTheme } from "@mui/material";
import { deDE } from "@mui/material/locale";
import "./fonts/typeface.css";

export const colors = {
  action: {
    disabledBackground: '#7878c4', // Disabled button color
    disabled: '#ffffff', // Disabled text color
  },
  primary: { // Primary button color
    light: "#000099",
    main: "#000099",
    dark: "#3c52b2", // Hover color
  },
  common: {
    black: "#000000",
    white: "#ffffff",
  },
  background: {
    default: "#eeeeee",
  },
  secondary: {
    main: "#000099",
    dark: "#3c52b2", // Hover color
    contrastText: "#ffffff",
  },
  success: {
    light: "#B5D462",
    main: "#94c11c",
    dark: "#85AE1B",
  },
  error: {
    light: "#E83348",
    main: "#e20E00",
    dark: "#CB0017",
  },
  info: {
    light: "#4c5fab",
    main: "#000099",
    dark: "#061671",
  },
  text: {
    primary: "#3b3b3b",
    secondary: "#9e9e9e",
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#dfdfdf",
    400: "#d3d3d3",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  status: {
    completed: "#000099",
    fixedWithFollowUp: "#94C11C",
    inProgress: "#FF9900",
    approvalPending: "#E2001A",
  },
 
};

const theme = createTheme(
  {
    
    typography: {
      fontFamily:
        '"EnBWDINPro","MarkOT", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: 15,
      h1: {
        fontSize: '42px',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "MarkOT"
      },
      h2: {
        fontSize: '36px',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "MarkOT"
      },
      h3: {
        fontSize: '26px',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "MarkOT"
      },
      h4: {
        fontSize: '22px',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "MarkOT"
      },
      h5: {
        fontSize: '18px',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "MarkOT"
      },
      h6: {
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: 0,
        fontFamily: "MarkOT"
      },
      caption: {
        fontSize: 12,
        letterSpacing: 0,
      },
      button: {
        textTransform: "none",
        fontWeight: 400,
      },
      
      body1: {
        fontSize: 14,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
      overline: {
        fontSize: 12,
      },
      subtitle1: {
        fontSize: 12,
      },
      subtitle2: {
        fontSize: 12,
      },
    },
    components: {

      MuiListItem: {
        styleOverrides: {
          root: {
            '& .MuiListItemIcon-root': {
              color: colors.primary.light,
            },
          },
        },
        
      },

      MuiAutocomplete: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          
            color: colors.primary.light,
            ":hover": {
              backgroundColor: '#EEEAE7',
              
            },
            ":focus": {
              backgroundColor: '#FFFFFF',
              
            },        
          },
          inputRoot: {
            borderRadius: '8px',
            ":hover": {
              backgroundColor: '#EEEAE7',
            },
            ":focus": {
              backgroundColor: '#FFFFFF',
              
            },
          }
          
        }
      },
      
      MuiTextField: {
          styleOverrides: {
            root: {
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
            },
             
              color: colors.primary.light,
              ":hover": {
                backgroundColor: '#EEEAE7',
                
              },
              ":focus": {
                backgroundColor: '#FFFFFF',         
              },           
            },
          }
      }
  },
  palette: { ...colors },
    
  },
  deDE
);

export default theme;

export const elevation = theme.shadows;
