import { Stepper, Step, StepButton, StepLabel, Typography } from "@mui/material";

interface StepperBarProps {
  index: number;
  steps: Array<StepInfo>;
}

interface StepInfo {
  label: string;
  icon: JSX.Element;
}

export const StepperBar = (props: StepperBarProps) => {
  const isActive = (i: number) => i === props.index;

  return (
    <Stepper activeStep={props.index} nonLinear alternativeLabel>
      {props.steps.map((step, index) => (
        <Step key={step.label}>
          <StepButton
            icon={step.icon}
          >
            <StepLabel>
              <Typography fontWeight={isActive(index) ? 500 : undefined}>{step.label}</Typography>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
