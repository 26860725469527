import { SvgIcon } from "@mui/material";
import React from "react";

export const ConfirmationIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <circle cx="20" cy="20" r="20" fill="currentColor" />
      <path
        d="M17.8194 27.4444C17.3793 27.4444 17.0859 27.301 16.7925 27.0142L11.2179 21.7088C10.6311 21.1352 10.6311 20.2749 11.2179 19.7013C11.8047 19.1277 12.6849 19.1277 13.2717 19.7013L17.6727 24.003L27.6482 12.6751C28.235 12.1016 29.1152 11.9582 29.702 12.5318C30.2888 13.1053 30.4355 13.9657 29.8487 14.5392L18.8463 27.0142C18.5529 27.301 18.2595 27.4444 17.8194 27.4444Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ConfirmationIcon;
