import { SvgIcon } from "@mui/material";
import React from "react";

export const FaultIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <circle cx="20" cy="20" r="20" fill="currentColor" />
      <path
        d="M16.5861 21.4228C12.0011 20.4251 14.3377 17.4938 17.2553 12.8691C21.4595 5.13443 22.8571 3.37777 21.6057 10.4633C20.5766 15.2839 21.0867 16.5644 23.6135 16.6113C27.9683 16.7744 25.5892 20.1985 22.6096 25.4323C17.8256 33.7017 16.8464 34.1789 18.5939 26.7688C19.1922 23.3808 19.0581 21.9197 16.5861 21.4228Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default FaultIcon;
