import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const ConfirmationStep = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="50px">
      <Typography color="primary" variant="h2" textAlign="center">
        Vielen Dank, dass Sie die Störung gemeldet haben!
      </Typography>
      <Typography variant="h6"  maxWidth="400px" textAlign="center">
        Ihre Meldung liegt nun der Kommune vor und wird nach Prüfung zur Behebung an die Netze BW weitergegeben.
      </Typography>
    </Box>
  );
};

export default ConfirmationStep;
