export enum LionState {
  APPROVAL_PENDING = "APPROVAL_PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  FIXED_WITH_FOLLOW_UP = "FIXED_WITH_FOLLOW_UP",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  RECEIVED = "RECEIVED",
};

export const LionStateLabel: Record<LionState, string> = {
  [LionState.APPROVAL_PENDING]: "Freigabe erforderlich",
  [LionState.IN_PROGRESS]: "In Bearbeitung",
  [LionState.FIXED_WITH_FOLLOW_UP]: "Behoben - Folgemaßnahmen laufen",
  [LionState.COMPLETED]: "Abgeschlossen",
  [LionState.REJECTED]: "Abgelehnt",
  [LionState.RECEIVED]: "Meldung eingegangen",
};