import { Box } from "@mui/system";
import { Routes, Route, Navigate } from "react-router-dom";
import FaultReportCreationPage from "./components/FaultReportCreation/FaultReportCreationPage";
import Home from "./components/Home";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FaultReportApi } from "./api/FaultReportApi";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import de from "date-fns/locale/de";
import { GeoMapProvider } from "./context/GeoMapContext";
import { useDefaultCenter } from "./hooks/defaultcenter";
import { MaintenanceDialog, MaintenanceReportsValue } from "./components/MaintenanceDialog";
import { FullscreenSpinner } from "./parts";
import { useAppContext } from "./context/AppContext";
import { LoadingDialog } from "./parts/LoadingDialog";
import { determineAPIBaseURL } from "./api/paths";

export const GOOGLE_MAPS_API_KEY = "AIzaSyAJKD2CxbWFEk5S2IVVD67fP7GdQJHy4T8";
const GOOGLE_MAPS_LIBRARIES = ["places", "geometry"];

function App({ maintenance, maintenanceFetched }: MaintenanceReportsValue) {
  const { isLoading } = useAppContext();
  const { defaultCenter } = useDefaultCenter();

  return !maintenanceFetched ? <FullscreenSpinner /> : (
    <>
      <GeoMapProvider 
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        googleMapslibraries={GOOGLE_MAPS_LIBRARIES}
        defaultCenter={defaultCenter}
        defaultFetchType="center"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
          <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_API_KEY} useEnterprise>
            <Box display="flex" flexDirection="column" height="100%">
              <Routes>
                <Route path="" element={<Navigate to="/start" />} />
                <Route path="/start" element={<Home />} />
                <Route
                  path="/stoerung-melden"
                  element={<FaultReportCreationPage faultReportApi={faultReportApi} />}
                />
              </Routes>
            </Box>
          </GoogleReCaptchaProvider>
        </LocalizationProvider>
      </GeoMapProvider>
      <LoadingDialog open={isLoading} />
      <MaintenanceDialog maintenance={maintenance} />
    </>
  );
}

const GOOGLE_RECAPTCHA_API_KEY = "6LfSxCwdAAAAANDP_6cG51fIqVU-8sVOWOphRJa2";

const faultReportApi = new FaultReportApi({ basePath: determineAPIBaseURL() || "" });

export default App;
