import { isAfter, isBefore, isEqual } from "date-fns";
import { MaintenanceReport } from "../../models/MaintenanceReport";

export namespace MaintenanceUtils {
  export function getCurrentMaintenance(reports: MaintenanceReport[], currentDateTime: Date) {
    for (const report of reports) {
      const startDate = new Date(report.startDateTime);
      const endDate = new Date(report.endDateTime);
      if (isInPeriodOfTime(currentDateTime, startDate, endDate)) {
        return report;
      }
    }
    return undefined;
  }
}

function isInPeriodOfTime(comparingDate: Date, start: Date, end: Date) {
  return (
    isEqual(comparingDate, start) ||
    isEqual(comparingDate, end) ||
    (isAfter(comparingDate, start) && isBefore(comparingDate, end))
  );
}