import { CreateFaultReport } from "../models/CreateFaultReport";

export class FaultReportApi {
  basePath: string;
  xApiKey = "GooRDiKrSj5eevcvjecCnaB3wTaVqllK3ShNF6rd";

  constructor({ basePath }: { basePath: string }) {
    this.basePath = basePath;
  }

  preparePost(params: FaultReportsApiPostParams): FaultReportsPostRequestConfig {
    return {
      url: `${this.basePath}/v2/unqualifiedfaultreports?`,
      method: "POST",
      body: JSON.stringify(params.body),
      headers: {
        "content-type": "application/json",
        "x-api-key": this.xApiKey,
        "x-captcha-verification": params.captchaToken
      },
    };
  }

  async post(params: FaultReportsApiPostParams): Promise<string | undefined> {
    const { url, method, body, headers } = this.preparePost(params);
    const resp = await fetch(url, { method, body, headers });
    if (!resp.ok) throw new Error(resp.statusText);
    return resp.headers.get("location")?.split("/").pop();
  }
}

interface FaultReportsPostRequestConfig {
  url: string;
  method: "POST";
  body: string;
  headers: {
    "content-type": "application/json";
    "x-api-key": string;
    "x-captcha-verification": string;
  }, 
}

interface FaultReportsApiPostParams {
  body: CreateFaultReport;
  captchaToken: string;
}
