import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from "react";
import ConfirmationStep from "./ConfirmationStep";
import FaultDetailsStep from "./FaultDetailsStep/FaultDetailsStep";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FaultLocationStep from "./FaultLocationStep/FaultLocationStep";
import SummaryStep from "./SummaryStep/SummaryStep";
import { FaultReportApi } from "../../api/FaultReportApi";
import { CreateFaultReport } from "../../models/CreateFaultReport";
import { Symptom } from "../../models/Symptom"
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import LightPointIcon from "../../assets/LightPointIcon";
import ConfirmationIcon from "../../assets/ConfirmationIcon";
import FaultIcon from "../../assets/FaultIcon";
import SummaryIcon from "../../assets/SummaryIcon";
import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";
import { useGeoMap } from '../../context/GeoMapContext';
import { Stepper } from '../Stepper';
import { useAppContext } from '../../context/AppContext';
import { NetArea } from '../../models';

interface Props {
  faultReportApi: FaultReportApi;
}
export const FaultReportCreationPage = ({ faultReportApi }: Props) => {
  const { setSelectedMarker, selectedMarker } = useGeoMap();
  const { setIsLoading } = useAppContext();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [snackbarOpen, toggleSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState<number[]>([]);
  const [recognitionDateTime, setRecognitionDateTime] = useState(new Date());
  const [symptoms, setSymptoms] = useState<Symptom[]>(initialValues.symptoms);
  const [remarksCitizen, setRemarksCitizen] = useState(initialValues.remarksCitizen);

  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    toggleSnackbarOpen(false);
  };

  const clear = useCallback(() => {
    setSelectedMarker(undefined);
    setSymptoms(initialValues.symptoms);
    setRemarksCitizen("");
    setRecognitionDateTime(new Date());
    setVisitedSteps([]);
  }, [ setSelectedMarker ]);

  useEffect(() => {
    setVisitedSteps(s => [...s, activeStep])
  }, [ activeStep ]);
  

  const handleSubmit = useCallback(async () => {
    if (!executeRecaptcha) {
      onError("Execute recaptcha not yet available");
      return;
    }
    if (!selectedMarker) {
      return;
    }
    try {
      setIsLoading(true);
      const captchaToken = await executeRecaptcha("createUnqualifiedFaultReport");
      const zipCode = await determineZipCode({ lat: selectedMarker.latitude, lng: selectedMarker.longitude });
      if (!zipCode) {
        console.error(
          "Error retrieving the postal code for the selected marker. Without the postal code, the form cannot be submitted."
        );
        return;
      }
      try {
        await faultReportApi.post({ 
          body: {
            lightingNo: selectedMarker.number,
            controlCabinetNo: selectedMarker.cabinetNumber,
            houseNo: selectedMarker.houseNumber,
            netArea: selectedMarker.netArea || initialValues.netArea,
            city: selectedMarker.city,
            customerScope: selectedMarker.customerScope,
            street: selectedMarker.street,
            zipCode,
            symptoms,
            recognitionDateTime: recognitionDateTime.toISOString(),
            remarksCitizen: remarksCitizen || undefined,
          }, 
          captchaToken 
        });
    } catch (error) {
        onError(error);
        throw error;
      }
    } catch (error) {
      onError(error)
    } finally {
      setIsLoading(false);
      setActiveStep(s => s + 1);
      clear();
    }
  }, [
    selectedMarker, 
    symptoms, 
    remarksCitizen, 
    recognitionDateTime, 
    executeRecaptcha, 
    faultReportApi, 
    clear,
    setIsLoading
  ]);

  const onError = (error: unknown) => {
    setSnackbarMessage("Die Störung konnte leider nicht gemeldet werden. Bitte versuchen Sie es später nochmal.");
    toggleSnackbarOpen(true);
    console.error("Error submitting the fault report creation form", error);
  }

  const getIconProps = (index: number) => ({
    color: index === activeStep ? "secondary" : "primary",
    style: {
      color: !visitedSteps.includes(index) && index !== activeStep && theme.palette.grey[500],
    },
  });

  function handleCancel() {
    setSelectedMarker(undefined);
    navigate("/");
  }

  function handleOnFurtherFaultReports() {
    clear();
    setActiveStep(0);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="1000px"
      alignSelf="center"
      gap="30px"
      flex={1}
      width="100%"
    >
      <Stepper
        activeStep={activeStep}
        onActiveStepChange={setActiveStep}
        steps={[
          {
            label: "Leuchte",
            content: <FaultLocationStep />,
            icon: <LightPointIcon {...getIconProps(0)} />,
            disableSubmit: !selectedMarker || !!selectedMarker.incident,
            secondaryBtnLabel: "Abbrechen",
            onSecondaryAction: handleCancel,
          },
          {
            label: "Störung",
            content: 
              <FaultDetailsStep
                onRecognitionDateTimeChange={d => setRecognitionDateTime(d)}
                onSelectSymptoms={s => setSymptoms(s)}
                onSymptomOther={s => setRemarksCitizen(s)}
                symptomBoxValue={{ symptoms, symptomOther: remarksCitizen }}
              />
            ,
            disableSubmit: !symptoms.length && !remarksCitizen,
            icon: <FaultIcon {...getIconProps(1)} />,
          },
          {
            label: "Übersicht",
            content: 
              <SummaryStep 
                symptoms={symptoms}
                recognitionDateTime={recognitionDateTime}
                remarksCitizen={remarksCitizen}
              />,
            icon: <SummaryIcon {...getIconProps(2)} />,
            submitBtnLabel: "Störung melden",
            onSubmit: handleSubmit,
          },
          {
            label: "Bestätigung",
            content: <ConfirmationStep />,
            icon: <ConfirmationIcon {...getIconProps(3)} />,
            submitBtnLabel: "Weitere Störung melden",
            onSubmit: handleOnFurtherFaultReports
          }
        ]}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "30px",
          alignItems: "center",
          marginBottom: "5vh"
        }}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert variant="filled" severity="error" onClose={handleSnackbarClose}>{snackbarMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

const initialValues: CreateFaultReport = {
  lightingNo: "",
  controlCabinetNo: "",
  city: "",
  customerScope: "",
  cityDistrict: undefined,
  houseNo: undefined,
  zipCode: "",
  street: "",
  netArea: NetArea.NO_CODE,
  symptoms: []
};

const determineZipCode = async (location: google.maps.LatLngLiteral) => {
  const request: google.maps.GeocoderRequest = {
    location,
  };
  const geocoder = new google.maps.Geocoder();
  try {
    const address = await geocoder.geocode(request);
    const zipCode = address?.results
      .map((res) => res.address_components)
      .filter((components) => components.some((component) => component.types.includes("postal_code")))
      .shift()
      ?.filter((component) => component.types.includes("postal_code"))
      .shift()?.long_name;

    return zipCode;
  } catch (error) {
    console.error("Error occured while geocoding the selected marker's location to get the zip code", error);
  }
};

export default FaultReportCreationPage;
