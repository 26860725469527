import { AutocompleteProps as MuiAutocompleteProps, Autocomplete as MuiAutocomplete, TextField, AutocompleteChangeReason, AutocompleteInputChangeReason, Typography } from "@mui/material";

type LightOnMuiAutocompleteProps<T> = Omit<MuiAutocompleteProps<
  T,
  undefined,
  boolean | undefined,
  boolean | undefined
>, "onChange"|"inputValue"|"onInputChange"|"renderInput"|"sx">;


interface AutocompleteProps extends LightOnMuiAutocompleteProps<GoogleSearchItemResult> {
  label?: string;
  value: string;
  onChange: (v: string, placeId?:string) => void;
  error?: boolean;
  errorMessage?: string;
}

export interface GoogleSearchItemResult {
  description:string;
  place_id:string
}

export function Autocomplete({ 
    placeholder, 
    label, 
    value, 
    onChange, 
    error, 
    errorMessage, 
    style,
    className,
    freeSolo = true,
    ...props }: AutocompleteProps
) {

  function handleOnChange(
    _: React.SyntheticEvent<Element, Event>,
    value: GoogleSearchItemResult | string | null,
    reason: AutocompleteChangeReason
  ) {

    if (typeof value ===  'object') {
      switch (reason) {
        case "selectOption": return onChange(value?.description || "",value?.place_id);
        case "clear": return onChange("");
      }
    }

    switch (reason) {
      case "selectOption": return onChange(value?.toString() || "");
      case "clear": return onChange("");
    }
  }

  function handleOnInputChange(    
      _: React.SyntheticEvent,
      value: string,
      __: AutocompleteInputChangeReason)
  {
    onChange(value);
  }

  return (
    <div className={props.fullWidth ? "w-full " : "" + className || ""} style={style}>
      <MuiAutocomplete
        freeSolo={freeSolo}
        renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
        inputValue={value}
        getOptionLabel={(item) => item.description }
        onInputChange={handleOnInputChange}
        onChange={handleOnChange}
        {...props}
        
      />
      {!error ? null : <Typography color="error" variant="caption">{errorMessage}</Typography>}
    </div>
  );
}